export const CoffeeIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 22"
    >
      <path
        d="M17 8h1a4 4 0 1 1 0 8h-1m0-8H1v9a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V8ZM5 1v3m4-3v3m4-3v3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
