import { MenuAlt4Icon } from '@heroicons/react/solid';
import {
  Root as NavigationMenu,
  List as NavigationList,
} from '@radix-ui/react-navigation-menu';
import { Trigger as PopoverTrigger } from '@radix-ui/react-popover';

import { classNames, getLinkProps } from '~/lib';
import { Button } from '../Button';
import { Link } from '../Link';
import { Logo } from '../Logo';
import { NavItem } from './NavItem';

export const Nav = ({
  navigation,
  darkMode,
  isScrolled,
}: {
  navigation?: any;
  darkMode?: boolean;
  isScrolled?: boolean;
}) => {
  return (
    <>
      <div
        className={classNames(
          'flex justify-between items-center px-4 py-4 lg:space-x-10',
        )}
      >
        <Link className="flex justify-start outline-none" href="/">
          <div />
          <Logo
            className={classNames(
              'h-9 w-[180px] mr-2 flex-1',
              darkMode && !isScrolled && 'text-white',
            )}
          />
        </Link>

        <div className={'lg:hidden'}>
          <PopoverTrigger
            className={classNames(
              'rounded-md p-2 inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500',
              darkMode && !isScrolled
                ? 'text-white hover:text-gray-400 hover:bg-gray-800'
                : 'text-gray-900 hover:text-gray-700 hover:bg-gray-100',
            )}
          >
            <span className="sr-only">Open menu</span>
            <MenuAlt4Icon className="h-6 w-6" aria-hidden="true" />
          </PopoverTrigger>
        </div>

        <NavigationMenu
          orientation="horizontal"
          className="w-full max-w-2xl hidden lg:flex justify-center"
        >
          <NavigationList className="w-full flex items-center justify-center space-x-2 xl:space-x-5">
            {navigation?.content?.links?.map((link) => (
              <NavItem
                key={link?._uid}
                link={link}
                darkMode={darkMode}
                isScrolled={isScrolled}
              />
            ))}
          </NavigationList>
        </NavigationMenu>

        <div
          className={classNames(
            'hidden gap-2 items-center justify-end',
            'hidden lg:flex',
          )}
        >
          {!!navigation?.content?.button_1_label && (
            <Button
              variant="outline"
              href={getLinkProps(navigation?.content?.button_1_url)}
              className="whitespace-nowrap text-sm lg:py-2 lg:px-4 xl:py-3 xl:px-6"
            >
              {navigation?.content?.button_1_label}
            </Button>
          )}
          {!!navigation?.content?.button_2_label && (
            <Button
              variant="primary"
              href={getLinkProps(navigation?.content?.button_2_url)}
              className="whitespace-nowrap text-sm lg:py-2 lg:px-4 xl:py-3 xl:px-6"
            >
              {navigation?.content?.button_2_label}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
