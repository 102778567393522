import { useEffect, useState } from 'react';
import { Image } from '@storyofams/storyblok-toolkit';
import { classNames } from '~/lib';
import { Heading } from '..';
import {
  BeakerIcon,
  CloudIcon,
  Codepen,
  DatabaseIcon,
  DeviceIcon,
  EyeOpenIcon,
  LinkIcon,
  ShieldCheckIcon,
  SparklesIcon,
  TranslateIcon,
  RefreshIcon,
  HardDrive,
  ShoppingBag,
  ToggleRight,
  MessageIcon,
  TrelloIcon,
  CoffeeIcon,
  PenTool,
  TrendingUp,
  Clock,
  Check,
  Zap,
  Aperture,
  Archive,
  Lock,
} from '../Icons';
import { FeatureBulletTimer } from '../Molecules';

export const smallFeatureIcons = [
  { value: 'aperture', icon: Aperture },
  { value: 'archive', icon: Archive },
  { value: 'message', icon: MessageIcon },
  { value: 'trello', icon: TrelloIcon },
  { value: 'beaker', icon: BeakerIcon },
  { value: 'cloud', icon: CloudIcon },
  { value: 'database', icon: DatabaseIcon },
  { value: 'device', icon: DeviceIcon },
  { value: 'eyeOpen', icon: EyeOpenIcon },
  { value: 'link', icon: LinkIcon },
  { value: 'lock', icon: Lock },
  { value: 'refresh', icon: RefreshIcon },
  { value: 'shieldCheck', icon: ShieldCheckIcon },
  { value: 'sparkles', icon: SparklesIcon },
  { value: 'translate', icon: TranslateIcon },
  { value: 'codepen', icon: Codepen },
  { value: 'hardDrive', icon: HardDrive },
  { value: 'shoppingBag', icon: ShoppingBag },
  { value: 'toggleRight', icon: ToggleRight },
  { value: 'coffee', icon: CoffeeIcon },
  { value: 'penTool', icon: PenTool },
  { value: 'clock', icon: Clock },
  { value: 'trendingUp', icon: TrendingUp },
  { value: 'check', icon: Check },
  { value: 'zap', icon: Zap },
];

interface FeatureSectionProps {
  section?: any;
  className?: string;
}

export const FeatureSection = ({ section, className }: FeatureSectionProps) => {
  const [currentFeature, setCurrentFeature] = useState<number>(0);
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout>();

  const timer = () => {
    const timeout = setTimeout(() => {
      if (currentFeature < section?.features?.length - 1) {
        setCurrentFeature(currentFeature + 1);
      } else {
        setCurrentFeature(0);
      }
    }, 8000);

    setTimeoutID(timeout);
  };

  useEffect(() => {
    timer();
  }, [currentFeature]);

  return (
    <div className="w-full overflow-hidden">
      <div
        className={classNames(
          'flex flex-col md:flex-row justify-center items-center pb-28 relative max-w-6xl mx-auto px-4',
          className,
        )}
      >
        <div className="w-full">
          <div className="max-w-2xl lg:max-w-[464px] flex flex-col items-start">
            <Heading variant="h5" as="h5" className="text-xl text-yellow-600">
              {section?.title}
            </Heading>
            <Heading variant="h3" as="h3" className="mt-4">
              {section?.description}
            </Heading>
          </div>
          <div className="w-[120%] md:hidden mt-8 lg:mt-0 lg:pl-0 lg:absolute right-0 mr-[20%]">
            <div className={classNames('w-full')}>
              <Image
                alt={section?.features[currentFeature]?.image?.alt || ''}
                src={section?.features[currentFeature]?.image?.filename}
                fit="contain"
                showPlaceholder={false}
                className={classNames('w-full')}
              />
            </div>
          </div>
          <div className="w-full max-w-6xl mx-auto px-4">
            <div className="max-w-2xl lg:max-w-[464px] flex flex-col items-start">
              <div className="mt-8">
                {section?.features?.map((feat, i) => {
                  const FeatureIcon = smallFeatureIcons.find(
                    (feature) => feature?.value === feat?.icon,
                  )?.icon;

                  return (
                    <FeatureBulletTimer
                      key={feat?._uid}
                      i={i}
                      Icon={FeatureIcon}
                      text={feat?.text}
                      currentFeature={currentFeature === i}
                      setCurrentFeature={setCurrentFeature}
                      timeoutID={timeoutID}
                      className="mb-5 last:mb-0"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="hidden w-full md:flex items-center mt-8 lg:mt-0 pl-4 lg:pl-0 -mr-[20%] h-[516px]">
          <div className={classNames('w-full')}>
            <Image
              alt={section?.features[currentFeature]?.image?.alt || ''}
              src={section?.features[currentFeature]?.image?.filename}
              fit="contain"
              showPlaceholder={false}
              className={classNames('w-full')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
