import { getLinkProps } from '~/lib';
import { Container } from '../Container';
import { social } from '../Layout';
import { Link } from '../Link';
import { Logo } from '../Logo';

export const Footer = ({ footer }) => {
  return (
    <Container className="pb-0 lg:pb-0">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="flex flex-col max-w-xs md:mb-auto">
          <div className="flex-1 self-start">
            <Link className="flex items-center" href="/">
              <div />
              <Logo className="w-auto h-11 mr-2" />
            </Link>
          </div>
          <div className="text-gray-500 text-sm mt-5">
            {footer?.content?.description}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 justify-between w-full sm:flex sm:justify-start lg:justify-end lg:ml-16">
          {footer?.content?.link_columns?.map(({ _uid, title, link_list }) => (
            <div
              key={_uid}
              className="flex flex-col mt-14 w-full lg:mt-0 max-w-[265px]"
            >
              <span className="text-sm text-left mb-6 text-gray-500 font-medium">
                {title}
              </span>
              <ul className="flex flex-col text-base items-start gap-6 ">
                {link_list?.map(({ link_url, link_label, _uid }) => (
                  <li className="" key={_uid}>
                    <Link
                      href={getLinkProps(link_url)}
                      className="text-gray-800 hover:text-gray-700 font-medium text-base"
                    >
                      {link_label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="relative flex items-center pt-10 mt-12 mb-20 border-t border-gray-200 flex-col sm:flex-row sm:pt-12 sm:mt-20 sm:justify-between">
        <span className="text-gray-400 text-sm mx-auto mb-6 opacity-70 sm:m-0 xl:text-center">
          &copy; Instant Commerce {new Date().getFullYear()}
        </span>
        <div className="flex justify-center items-center space-x-8">
          {social.map((item) => {
            if (!footer?.content?.[item.key]) {
              return null;
            }

            return (
              <Link
                key={item.key}
                href={getLinkProps(footer?.content?.[item.key])}
                className="text-gray-400 hover:text-gray-700"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-8 w-8" aria-hidden="true" />
              </Link>
            );
          })}
        </div>
      </div>
    </Container>
  );
};
