export const Clock = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
    >
      <path
        d="m1 1 14.5 3.5L17 12l-5 5-7.5-1.5L1 1Zm0 0 7.586 7.586M11 18l7-7 3 3-7 7-3-3Zm1-8a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
