import { Item as MenuItem } from '@radix-ui/react-navigation-menu';

import { classNames, getLinkProps } from '~/lib';
import { Link } from '../Link';
import { NavItemProps } from './NavItem';

export const NavLink = ({ link, darkMode, isScrolled }: NavItemProps) => {
  return (
    <MenuItem>
      <Link key={link?._uid} href={getLinkProps(link?.link_url)}>
        <a
          className={classNames(
            'p-3 text-gray-900 hover:text-gray-700 text-sm xl:text-md block',
            darkMode && !isScrolled
              ? 'nav:text-white nav:hover:text-gray-400'
              : darkMode &&
                  !isScrolled &&
                  'md:text-white md:hover:text-gray-400',
          )}
        >
          {link?.link_label}
        </a>
      </Link>
    </MenuItem>
  );
};
