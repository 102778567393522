export const ShieldCheckIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="htt#b38686.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="m7 10 2 2 4-4m5.618-4.016A11.955 11.955 0 0 1 10 .944a11.955 11.955 0 0 1-8.618 3.04A12.02 12.02 0 0 0 1 7c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016Z"
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
