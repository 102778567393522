import hoistNonReactStatics from 'hoist-non-react-statics';
import { useRouter } from 'next/router';

import { Layout } from '~/components';
import { Layout as LandingLayout } from '~/components/Landing';

const getDisplayName = (Component) => Component.displayName || Component.name;

export function withLayout(Component: any) {
  const LayoutComponent = ({
    announcementBar,
    footer,
    navigation,
    cookieBanner,
    landingSettings,
    onboardingContent,
    ...props
  }) => {
    const router = useRouter();
    const { pathname } = router;
    const darkMode = pathname === '/guides/[name]';
    const isLanding = pathname?.startsWith('/lp/');

    return isLanding ? (
      <LandingLayout
        cookieBanner={cookieBanner}
        landingSettings={landingSettings}
      >
        <Component {...props} />
      </LandingLayout>
    ) : (
      <Layout
        announcementBar={announcementBar}
        footer={footer}
        navigation={navigation}
        cookieBanner={cookieBanner}
        darkMode={darkMode}
      >
        <Component {...props} />
      </Layout>
    );
  };

  hoistNonReactStatics(LayoutComponent, Component);

  LayoutComponent.displayName = `withLayout(${getDisplayName(Component)})`;

  return LayoutComponent;
}
