import { XIcon } from '@heroicons/react/solid';
import {
  Root as NavigationMenu,
  List as NavigationList,
} from '@radix-ui/react-navigation-menu';
import { Close as PopoverClose } from '@radix-ui/react-popover';

import { classNames, getLinkProps } from '~/lib';
import { Button } from '../Button';
import { Link } from '../Link';
import { Logo } from '../Logo';
import { NavItem } from './NavItem';

export const MobileNav = ({
  navigation,
  darkMode,
  isScrolled,
}: {
  navigation?: any;
  darkMode?: boolean;
  isScrolled?: boolean;
}) => {
  return (
    <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
      <div className="pt-2 pb-6 px-2">
        <div className="flex items-center justify-between p-2">
          <Link className="flex justify-start outline-none" href="/">
            <div />
            <Logo className={classNames('w-[180px] mr-2')} />
          </Link>
          <div className="">
            <PopoverClose className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Close menu</span>
              <XIcon className="h-6 w-6" aria-hidden />
            </PopoverClose>
          </div>
        </div>

        <NavigationMenu className="mt-4">
          <NavigationList className="grid grid-cols-1 gap-2 max-h-[calc(100vh-80px)] overflow-y-scroll pb-6">
            {navigation?.content?.links?.map((link) => (
              <NavItem
                key={link?._uid}
                link={link}
                darkMode={darkMode}
                isScrolled={isScrolled}
              />
            ))}
            {!!navigation?.content?.button_1_label && (
              <Button
                variant="outline"
                href={getLinkProps(navigation?.content?.button_1_url)}
                className="whitespace-nowrap"
              >
                {navigation?.content?.button_1_label}
              </Button>
            )}
            {!!navigation?.content?.button_2_label && (
              <Button
                variant="primary"
                href={getLinkProps(navigation?.content?.button_2_url)}
                className="whitespace-nowrap"
              >
                {navigation?.content?.button_2_label}
              </Button>
            )}
          </NavigationList>
        </NavigationMenu>
      </div>
    </div>
  );
};
