import React, { useEffect, useState } from 'react';
import { Richtext } from '@storyofams/storyblok-toolkit';
import { setCookie, hasCookie } from 'cookies-next';
import { gtag } from '~/lib';
import { Heading, RichText, Button } from '..';

type CookieBannerProps = {
  title?: string;
  text?: Richtext;
  decline_button?: string;
  accept_button?: string;
};

export const CookieBanner = ({ content }: { content: CookieBannerProps }) => {
  const [consent, setConsent] = useState<boolean>(false);

  useEffect(() => {
    setConsent(hasCookie('localConsent'));
  }, []);

  const acceptCookie = () => {
    setConsent(true);
    setCookie('localConsent', 'true', { maxAge: 60 * 60 * 24 * 30 });
    gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  };

  const denyCookie = () => {
    setConsent(true);
    setCookie('localConsent', 'false', { maxAge: 60 * 60 * 24 * 30 });
    gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    });
  };
  if (consent === true) {
    return null;
  }

  return (
    <div
      className={`fixed z-20 bottom-4 w-auto mx-4 md:mx-0 md:max-w-xl md:right-4 p-3 rounded-2xl bg-grey-800 px-6 md:px-10 py-8 shadow-md text-white ${
        consent ? 'hidden' : ''
      }`}
    >
      {!!content?.title && (
        <Heading as="h4" variant="h4" className="!text-white">
          {content?.title}
        </Heading>
      )}
      {!!content?.text && (
        <RichText
          text={content?.text}
          className="!text-base mt-6 !text-white"
          darkMode
        />
      )}
      <div className="flex flex-col md:flex-row space-y-2 md:space-x-5 md:space-y-0 mt-6 md:mt-8">
        <Button
          variant="primary"
          onClick={() => {
            acceptCookie();
          }}
          className="w-full"
        >
          {content?.accept_button || 'Accept'}
        </Button>
        <Button
          variant="outline-transparent"
          onClick={(e) => denyCookie()}
          className="w-full"
        >
          {content?.decline_button || 'Decline'}
        </Button>
      </div>
    </div>
  );
};
