import { useState, useEffect } from 'react';

import { AnnouncementBar } from './Layout/AnnouncementBar';
import { CookieBanner } from './Layout/CookieBanner';
import { Footer } from './Layout/Footer';
import { Navigation } from './Layout/Navigation';

export const social = [
  {
    name: 'LinkedIn',
    key: 'linkedin',
    icon: (props) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  {
    name: 'Twitter',
    key: 'twitter',
    icon: (props) => (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M29.2932 8.61842C28.6051 9.53621 27.7734 10.3368 26.8302 10.9893C26.8302 11.2291 26.8302 11.4688 26.8302 11.7219C26.8378 16.0758 25.0987 20.2506 22.0027 23.3104C18.9068 26.3703 14.7131 28.0591 10.3617 27.9985C7.84604 28.0069 5.36261 27.4324 3.10593 26.3202C2.98425 26.2671 2.90577 26.1467 2.90623 26.0138V25.8673C2.90623 25.6761 3.0612 25.521 3.25238 25.521C5.72519 25.4395 8.10979 24.5821 10.0688 23.0702C7.83054 23.0251 5.8167 21.6992 4.88991 19.6604C4.8431 19.549 4.85767 19.4213 4.92837 19.3234C4.99907 19.2254 5.11568 19.1714 5.23606 19.1809C5.91631 19.2493 6.60336 19.1859 7.25968 18.9944C4.78885 18.4815 2.93229 16.4302 2.66659 13.9196C2.65715 13.7992 2.71112 13.6825 2.80901 13.6118C2.9069 13.5411 3.03456 13.5265 3.14587 13.5733C3.80893 13.8659 4.52471 14.02 5.24937 14.0262C3.08434 12.6052 2.14918 9.90233 2.9728 7.44629C3.05781 7.20763 3.26206 7.03133 3.51049 6.98217C3.75892 6.93301 4.01488 7.01824 4.18431 7.20654C7.10589 10.316 11.1202 12.1689 15.3808 12.3745C15.2717 11.9391 15.218 11.4915 15.221 11.0426C15.2609 8.68852 16.7174 6.59146 18.9088 5.73319C21.1002 4.87491 23.5926 5.42528 25.2193 7.12662C26.3282 6.91538 27.4002 6.54293 28.4012 6.02109C28.4745 5.97531 28.5675 5.97531 28.6408 6.02109C28.6866 6.09445 28.6866 6.18748 28.6408 6.26085C28.156 7.37117 27.3368 8.30244 26.2977 8.92477C27.2077 8.81924 28.1016 8.60459 28.9604 8.28543C29.0327 8.23621 29.1277 8.23621 29.2 8.28543C29.2606 8.31314 29.3059 8.36613 29.3238 8.43029C29.3418 8.49445 29.3306 8.56327 29.2932 8.61842Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  {
    name: 'GitHub',
    key: 'github',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Instagram',
    key: 'instagram',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 3H8C5.23858 3 3 5.23858 3 8V16C3 18.7614 5.23858 21 8 21H16C18.7614 21 21 18.7614 21 16V8C21 5.23858 18.7614 3 16 3ZM19.25 16C19.2445 17.7926 17.7926 19.2445 16 19.25H8C6.20735 19.2445 4.75549 17.7926 4.75 16V8C4.75549 6.20735 6.20735 4.75549 8 4.75H16C17.7926 4.75549 19.2445 6.20735 19.25 8V16ZM16.75 8.25C17.3023 8.25 17.75 7.80228 17.75 7.25C17.75 6.69772 17.3023 6.25 16.75 6.25C16.1977 6.25 15.75 6.69772 15.75 7.25C15.75 7.80228 16.1977 8.25 16.75 8.25ZM12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5027 10.8057 16.0294 9.65957 15.1849 8.81508C14.3404 7.97059 13.1943 7.49734 12 7.5ZM9.25 12C9.25 13.5188 10.4812 14.75 12 14.75C13.5188 14.75 14.75 13.5188 14.75 12C14.75 10.4812 13.5188 9.25 12 9.25C10.4812 9.25 9.25 10.4812 9.25 12Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
];

export const Layout = ({
  announcementBar,
  children,
  footer,
  navigation,
  cookieBanner,
  darkMode,
}) => {
  const [isScrolled, setScrolled] = useState(false);

  const onScroll = () => {
    if (window.scrollY > 40) {
      if (!isScrolled) {
        setScrolled(true);
      }
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div>
      {!!announcementBar && !!announcementBar?.content?.text && (
        <AnnouncementBar announcementBar={announcementBar} />
      )}

      <Navigation
        announcementBar={announcementBar}
        navigation={navigation}
        isScrolled={isScrolled}
        darkMode={darkMode}
      />

      <main>{children}</main>

      <CookieBanner content={cookieBanner?.content} />

      <footer aria-labelledby="footer-heading" className="overflow-hidden">
        <Footer footer={footer} />
      </footer>
    </div>
  );
};
