import { Dispatch, SetStateAction } from 'react';
import { classNames } from '~/lib';

export const FeatureBulletTimer = ({
  Icon,
  text,
  className,
  currentFeature,
  setCurrentFeature,
  timeoutID,
  i,
}: {
  Icon?: any;
  text?: string;
  className?: string;
  currentFeature?: boolean;
  setCurrentFeature?: Dispatch<SetStateAction<number>>;
  i?: number;
  timeoutID?: NodeJS.Timeout;
}) => {
  return (
    <div
      className={classNames(
        'flex items-center justify-start mb-5 last:mb-0 cursor-pointer',
        className,
      )}
      onClick={() => {
        clearTimeout(timeoutID);
        setCurrentFeature(i);
      }}
    >
      <div className="rounded-full h-14 w-14 flex items-center justify-center relative">
        <div className="bg-yellow-100 rounded-full h-11 w-11 flex items-center justify-center border-4 border-white relative z-10">
          <Icon className="text-grey-900 w-4 h-4" />
        </div>
        <svg
          className="absolute w-14 h-14 countdown-svg"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            r="24"
            cx="28"
            cy="28"
            fill="#F3F4F6"
            className={currentFeature ? 'countdown-circle' : undefined}
          ></circle>
        </svg>
      </div>
      <div
        className={classNames(
          'text-lg ml-4 flex-1',
          currentFeature ? 'text-grey-800' : 'text-grey-500',
        )}
      >
        {text}
      </div>
    </div>
  );
};
