export const Aperture = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="23"
      height="22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m13.81 7 5.74 9.94M9.19 7h11.48M6.88 11l5.74-9.94M9.19 15 3.45 5.06M13.81 15H2.33m13.79-4-5.74 9.94M21.5 11c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
