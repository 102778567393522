import { ReactNode } from 'react';
import { Parallax } from 'react-scroll-parallax';

import { classNames } from '~/lib';

type ContainerProps = {
  children?: ReactNode;
  className?: string;
  first?: boolean;
  shape?: boolean | 'yellow' | 'yellow-xl' | 'light-md';
};

export const Container = ({
  children,
  first,
  className,
  shape,
}: ContainerProps) => {
  if (!!shape) {
    return (
      <div
        className={classNames(
          'relative overflow-hidden',
          shape === 'yellow' || shape === 'yellow-xl'
            ? 'text-yellow-100'
            : 'text-white',
        )}
      >
        {!!shape && (
          <Parallax
            x={[5, -5]}
            y={[5, -5]}
            styleOuter={{
              position: 'absolute',
              left: '-10%',
              top: '-5%',
              right: '-10%',
            }}
          >
            {shape === 'yellow-xl' && (
              <div className="bg-yellow-100 opacity-50 h-[600px]" />
            )}

            {shape === 'light-md' && (
              <div className="bg-white opacity-50 h-64" />
            )}
            <svg
              width="1440"
              height="525"
              viewBox="0 0 1440 525"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="lg:w-full"
              preserveAspectRatio="none"
            >
              <path
                opacity="0.5"
                d="M50.5 417C-136.7 425.4 -183.5 287 -203 225V0H1562.5C1562.5 69 1616.4 401.3 1354 500.5C1026 624.5 982.5 236.5 620 210.5C257.5 184.5 284.5 406.5 50.5 417Z"
                fill="currentColor"
              />
            </svg>
          </Parallax>
        )}
        <div
          className={classNames(
            `w-full flex flex-col max-w-6xl mx-auto px-4 py-20 lg:py-24 z-10 relative`,
            first && 'pt-32 lg:pt-32',
            className,
          )}
        >
          {children}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'w-full flex flex-col max-w-6xl mx-auto px-4 py-20 lg:py-24',
        first && 'pt-32 lg:pt-32',
        className,
      )}
    >
      {children}
    </div>
  );
};
