export const ShoppingBag = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 22"
    >
      <path
        d="m1 5 3-4h12l3 4M1 5v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5M1 5h18m-5 4a4 4 0 1 1-8 0"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
