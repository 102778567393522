import NextLink from 'next/link';
import { getLinkProps } from '~/lib';

export const AnnouncementBar = ({ announcementBar }) => {
  return (
    <div className={'relative bg-brand z-50'}>
      <div className="flex justify-center max-w-6xl mx-auto h-10">
        <p className="text-grey-900 text-sm relative px-4 py-2 group">
          <span className="hidden md:inline-block">
            {announcementBar?.content?.text}
          </span>
          <span className="inline-block md:hidden">
            {announcementBar?.content?.mobile_text}
          </span>
          {!!announcementBar?.content?.link?.[0]?.link_label && (
            <span className="ml-2 sm:inline-block font-medium">
              <span className="hidden md:inline-block">
                {announcementBar?.content?.link?.[0]?.link_label}
              </span>
              <span
                aria-hidden="true"
                className="inline-block md:ml-1 transition-transform group-hover:translate-x-0.5"
              >
                &rarr;
              </span>
              {announcementBar?.content?.link?.[0]?.link_url && (
                <NextLink
                  href={getLinkProps(
                    announcementBar?.content?.link?.[0]?.link_url,
                  )}
                  passHref
                >
                  <a className="absolute left-0 right-0 top-0 bottom-0 opacity-0">
                    {announcementBar?.content?.link?.[0]?.link_label}
                    <span className="sr-only md:hidden">
                      {' '}
                      on &quot;{announcementBar?.content?.mobile_text}&quot;
                    </span>
                    <span className="hidden md:inline-block sr-only">
                      {' '}
                      on &quot;{announcementBar?.content?.text}&quot;
                    </span>
                  </a>
                </NextLink>
              )}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};
