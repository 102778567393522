import { useRouter } from 'next/router';
import { HighlightList } from './HighlightList';
import { LinkList } from './LinkList';
import { NavLink } from './NavLink';

export interface NavItemProps {
  link?: any;
  darkMode?: boolean;
  isScrolled?: boolean;
  first?: boolean;
  last?: boolean;
}

export const NavItem = (props: NavItemProps) => {
  const { asPath } = useRouter();

  switch (props?.link?.component) {
    case 'link_list':
      return <LinkList key={asPath} {...props} />;
    case 'link':
      return <NavLink key={asPath} {...props} />;
    default:
      return <div></div>;
    case 'nav_item':
      return <HighlightList key={asPath} {...props} />;
  }
};
