export const ChevronDown = ({ className }: { className?: string }) => (
  <svg
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11 7"
  >
    <path
      d="m1.5 1.5 4 4 4-4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
