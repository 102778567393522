import SbEditable from 'storyblok-react';
import { classNames, getLinkProps } from '~/lib';
import { CookieBanner } from '../Layout/CookieBanner';
import { Link } from '../Link';
import { Logo } from '../Logo';

export const Layout = ({ cookieBanner, landingSettings, children }) => {
  const isLink = landingSettings?.content?.link_logo;

  return (
    <div>
      <SbEditable content={landingSettings?.content}>
        <nav
          className={classNames(
            'py-6 flex items-center justify-center w-full transition-all',
          )}
        >
          {isLink ? (
            <Link href="/" className="w-44">
              <div />
              <Logo className="w-full" />
            </Link>
          ) : (
            <Logo className="w-44" />
          )}
        </nav>
      </SbEditable>

      <main>{children}</main>

      {!!cookieBanner && <CookieBanner content={cookieBanner?.content} />}

      <SbEditable content={landingSettings?.content}>
        <footer
          aria-labelledby="footer"
          className="w-full overflow-hidden border-t p-10 md:p-12"
        >
          <div className="w-full flex flex-col md:flex-row md:items-center md:justify-between max-w-6xl px-4 mx-auto">
            {isLink ? (
              <Link href="/" className="w-44">
                <div />
                <Logo className="w-full" />
              </Link>
            ) : (
              <Logo className="w-44" />
            )}
            <div className="flex items-center justify-between space-x-2">
              {landingSettings?.content?.footer?.map((link) => (
                <Link
                  key={link?._uid}
                  href={getLinkProps(link?.link_url)}
                  className="text-sm py-2 md:px-4"
                >
                  {link?.link_label}
                </Link>
              ))}
            </div>
            <div className="text-gray-400 text-sm mx-auto mb-6 opacity-70 sm:m-0 xl:text-center">
              &copy; Instant Commerce {new Date().getFullYear()}
            </div>
          </div>
        </footer>
      </SbEditable>
    </div>
  );
};
