import {
  Item as MenuItem,
  Trigger,
  Content,
} from '@radix-ui/react-navigation-menu';
import { Image } from '@storyofams/storyblok-toolkit';

import { classNames, getLinkProps } from '~/lib';
import { smallFeatureIcons } from '../Blocks/FeatureSection';

import { ArrowRight, ChevronDown } from '../Icons';
import { Link } from '../Link';
import { NavItemProps } from './NavItem';

export const HighlightList = ({ link, darkMode, isScrolled }: NavItemProps) => {
  return (
    <MenuItem>
      <Trigger
        className={classNames(
          'relative p-3 rounded-lg w-full',
          'data-[state=open]:bg-grey-100 lg:data-[state=open]:bg-transparent [&>*>svg]:data-[state=open]:rotate-180',
        )}
      >
        <div
          className={classNames(
            'text-sm xl:text-md text-gray-900 hover:text-gray-700 flex justify-between items-center',
            darkMode && !isScrolled
              ? 'nav:text-white nav:hover:text-gray-400'
              : darkMode &&
                  !isScrolled &&
                  'sm:text-white sm:hover:text-gray-400',
          )}
        >
          <span>{link?.title}</span>
          <ChevronDown
            aria-hidden
            className={classNames(
              'block w-3 lg:w-2 ml-2.5 sm:ml-1 transition-transform',
              darkMode && 'text-gray-900 nav:text-white',
            )}
          />
        </div>

        <Content
          className={classNames(
            'min-w-[240px] flex flex-col mt-3 lg:mt-0',
            'lg:absolute lg:top-10 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:bg-white lg:px-5 lg:py-4 lg:rounded-lg lg:shadow-menu lg:border lg:border-grey-200 z-10',
          )}
        >
          {!link?.show_highlight && (
            <div className="text-sm text-gray-700 mb-2 hidden lg:block">
              {link?.header}
            </div>
          )}
          <div className="flex w-full flex-col xl:flex-row text-left">
            <div className="flex-1">
              {link?.show_highlight && (
                <div className="text-sm text-gray-700 mb-2 hidden lg:block">
                  {link?.header}
                </div>
              )}
              {link?.links?.slice(0, 3)?.map((l) => {
                const Icon = smallFeatureIcons.find((i) => i.value === l?.icon)
                  ?.icon;

                return (
                  <ul key={l?._uid}>
                    <Link href={getLinkProps(l?.link)}>
                      <a
                        className={classNames(
                          'p-3 flex items-center',
                          !!link?.showHighlight
                            ? 'min-w-[329px]'
                            : 'min-w-[296px]',
                        )}
                      >
                        <div className="rounded-full bg-yellow-50 w-12 h-12 hidden lg:flex lg:items-center lg:justify-center shrink-0 mr-4">
                          {!!Icon && <Icon className="w-5" />}
                        </div>
                        <div>
                          <div className="text-black text-sm lg:text-base">
                            {l?.link_text}
                          </div>
                          {!!l?.link_subtext && (
                            <div className="text-sm text-gray-400 hidden lg:block">
                              {l?.link_subtext}
                            </div>
                          )}
                        </div>
                      </a>
                    </Link>
                  </ul>
                );
              })}
            </div>

            {link?.show_highlight ? (
              <ul>
                <Link href={getLinkProps(link?.highlight_link)}>
                  <a className="xl:px-6 xl:py-4 w-full xl:min-w-[247px] xl:border-l flex flex-col justify-between group">
                    <div className="w-full">
                      <div className="w-full rounded-md overflow-hidden mt-4 sm:mt-0 hidden lg:block">
                        <Image
                          alt={link?.thumbnail?.alt || ''}
                          src={link?.thumbnail?.filename}
                          fit="contain"
                          showPlaceholder={false}
                          className={classNames('w-full rounded-md')}
                        />
                      </div>
                      <div className="p-3 lg:p-0 text-sm lg:text-base lg:mt-3">
                        {link?.highlight_title}
                      </div>
                      <div className="text-sm text-gray-500 mt-1 hidden lg:block">
                        {link?.highlight_subtitle}
                      </div>
                    </div>

                    <div className="text-sm hidden lg:flex lg:items-center mt-6 sm:mt-0">
                      <span>{link?.cta_text || 'Read more'}</span>
                      <ArrowRight className="w-3 ml-2 transition-transform group-hover:translate-x-0.5" />
                    </div>
                  </a>
                </Link>
              </ul>
            ) : (
              <div className="flex-1">
                {link?.links?.slice(3)?.map((l) => {
                  const Icon = smallFeatureIcons.find(
                    (i) => i.value === l?.icon,
                  )?.icon;

                  return (
                    <ul key={l?._uid}>
                      <Link href={getLinkProps(l?.link)}>
                        <a
                          className={classNames(
                            'p-3 flex items-center',
                            !!link?.showHighlight
                              ? 'min-w-[329px]'
                              : 'min-w-[296px]',
                          )}
                        >
                          <div className="rounded-full bg-yellow-50 w-12 h-12 hidden lg:flex lg:items-center lg:justify-center shrink-0 mr-4">
                            {!!Icon && <Icon className="w-5" />}
                          </div>
                          <div>
                            <div className="text-black text-sm lg:text-base">
                              {l?.link_text}
                            </div>
                            {!!l?.link_subtext && (
                              <div className="text-sm text-gray-400 hidden lg:block">
                                {l?.link_subtext}
                              </div>
                            )}
                          </div>
                        </a>
                      </Link>
                    </ul>
                  );
                })}
              </div>
            )}
          </div>
        </Content>
      </Trigger>
    </MenuItem>
  );
};
