export const Archive = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="25"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5 6v13h-18V6m7 4h4m-13-9h22v5h-22V1Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
