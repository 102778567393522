import {
  Item as MenuItem,
  Trigger,
  Content,
} from '@radix-ui/react-navigation-menu';

import { classNames, getLinkProps } from '~/lib';
import { Button } from '../Button';
import { ChevronDown } from '../Icons';
import { Link } from '../Link';
import { NavItemProps } from './NavItem';

export const LinkList = ({ link, darkMode, isScrolled }: NavItemProps) => {
  return (
    <MenuItem>
      <Trigger
        className={classNames(
          'relative flex flex-col items-start w-full p-3 rounded-lg',
          'data-[state=open]:bg-grey-100 lg:data-[state=open]:bg-transparent [&>*>svg]:data-[state=open]:rotate-180',
        )}
      >
        <div
          className={classNames(
            'text-sm xl:text-md text-gray-900 hover:text-gray-700 relative flex justify-between items-center w-full',
            darkMode && !isScrolled
              ? 'lg:text-white lg:hover:text-gray-400'
              : darkMode &&
                  !isScrolled &&
                  'sm:text-white sm:hover:text-gray-400',
          )}
        >
          <span>{link?.title}</span>

          <ChevronDown
            aria-hidden
            className={classNames(
              'w-3 lg:w-2 ml-2.5 sm:ml-1 transition-transform',
              darkMode && 'text-gray-900 nav:text-white',
            )}
          />
        </div>

        <Content
          className={classNames(
            'w-[240px] flex flex-col items-start lg:p-8 lg:rounded-lg lg:shadow-menu lg:border lg:border-grey-200',
            'lg:absolute lg:top-10 lg:left-1/2 lg:transform lg:-translate-x-1/2',
            'lg:data-[state=open]:bg-white',
            'mt-3 lg:mt-0',
          )}
        >
          {link?.list?.map((l) => (
            <ul key={l?._uid} className="my-2 mx-4 lg:mx-3 text-sm xl:text-md">
              <Link href={getLinkProps(l?.link_url)}>
                <a>{l?.link_label}</a>
              </Link>
            </ul>
          ))}
          {link?.cta_button && (
            <Button
              href={getLinkProps(link?.cta_link)}
              variant="primary"
              className={classNames('mt-5 py-3 hidden', 'md:flex')}
            >
              {link?.cta_button}
            </Button>
          )}
        </Content>
      </Trigger>
    </MenuItem>
  );
};
