export const Check = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 13"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.946.622 4.592 8.683 2.376 6.315c-.409-.385-1.05-.408-1.517-.082-.455.339-.583.934-.303 1.412l2.625 4.27c.256.397.7.642 1.201.642.479 0 .934-.245 1.19-.642.42-.548 8.436-10.103 8.436-10.103C15.058.738 13.786-.207 12.946.61v.012Z"
        fill="currentColor"
      />
    </svg>
  );
};
