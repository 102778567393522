export const TranslateIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M1 3h12M7 1v2m1.048 9.5A18.023 18.023 0 0 1 4.412 7m6.088 9h7M9 19l5-10 5 10M10.751 3C9.783 8.77 6.07 13.61 1 16.129"
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
