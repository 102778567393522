export const SparklesIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M3 1v4M1 3h4M4 15v4m-2-2h4m5-16 2.286 6.857L19 10l-5.714 2.143L11 19l-2.286-6.857L3 10l5.714-2.143L11 1Z"
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
