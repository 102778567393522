export const RefreshIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      className={className}
    >
      <path
        d="M1 1v5h.582m15.356 2A8.001 8.001 0 0 0 1.582 6m0 0H6m11 11v-5h-.581m0 0a8.003 8.003 0 0 1-15.357-2m15.357 2H12"
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
