export const Codepen = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
    >
      <path
        d="m11 1 10 6.5M11 1 1 7.5M11 1v6.5m10 0v7m0-7-10 7m10 0L11 21m10-6.5-10-7M11 21 1 14.5M11 21v-6.5m-10 0v-7m0 7 10-7m-10 0 10 7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
