export const HardDrive = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 18"
    >
      <path
        d="M21 9H1m20 0v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9m20 0-3.45-6.89A2 2 0 0 0 15.76 1H6.24a2 2 0 0 0-1.79 1.11L1 9m4 4h.01M9 13h.01"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
