import { Root as PopoverRoot, Anchor, Content } from '@radix-ui/react-popover';

import { MobileNav } from './MobileNav';
import { Nav } from './Nav';

interface NavigationProps {
  announcementBar?: any;
  navigation?: any;
  isScrolled?: boolean;
  darkMode?: boolean;
}

export const Navigation = ({
  announcementBar,
  navigation,
  isScrolled,
  darkMode,
}: NavigationProps) => {
  return (
    <div
      className={`z-40 left-0 right-0 transition-colors ${
        isScrolled
          ? 'fixed top-0 shadow-sm bg-white shadow-yellow'
          : !!announcementBar?.content?.text && 'absolute top-10'
      }`}
    >
      <PopoverRoot>
        <Anchor />
        <Nav
          navigation={navigation}
          isScrolled={isScrolled}
          darkMode={darkMode}
        />

        <Content className="w-screen">
          <MobileNav
            navigation={navigation}
            darkMode={darkMode}
            isScrolled={isScrolled}
          />
        </Content>
      </PopoverRoot>
    </div>
  );
};
